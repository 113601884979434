<template>
	<main class="main">
		<div class="container-fluid">
			<!-- slider -->
			<section class="row">
				<div class="col-12">
					<div>



						<div>
							<div class="owl-stage" style="
                      
                         width: 2424px;">
								<div style="margin-right: 20px;">
									<div class="hero__slide" :data-bg="'https://tuneafrique.com/files/' + single.main"
										:style="'background: url(https://tuneafrique.com/files/' + single.main + ') center center / cover no-repeat;'">
										<h1 class="hero__title">{{ single.title }}</h1>
										<p class="hero__text">{{ single.location }}</p>
										<div class="hero__btns">
											<a v-if="single.link != ''" :href="single.link"
												class="hero__btn hero__btn--red open-modal"><svg
													xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path
														d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z">
													</path>
												</svg> Buy ticket</a>
											<a v-else href="#" class="hero__btn hero__btn--red open-modal"><svg
													xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path
														d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z">
													</path>
												</svg> Free</a>
											<a v-if="single.location != ''" href="#"
												class="hero__btn open-map">location: {{ single.location }}</a>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</section>
			<!-- end slider -->

			<div class="row row--grid">
				<div class="col-12 col-xl-10">
					<div class="article">
						<!-- article content -->
						<div class="article__content">
							<div class="article__meta">
								<a v-if="single.location != ''" href="#" class="article__place open-map"><svg
										xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
										<path
											d="M20.46,9.63A8.5,8.5,0,0,0,7.3,3.36,8.56,8.56,0,0,0,3.54,9.63,8.46,8.46,0,0,0,6,16.46l5.3,5.31a1,1,0,0,0,1.42,0L18,16.46A8.46,8.46,0,0,0,20.46,9.63ZM16.6,15.05,12,19.65l-4.6-4.6A6.49,6.49,0,0,1,5.53,9.83,6.57,6.57,0,0,1,8.42,5a6.47,6.47,0,0,1,7.16,0,6.57,6.57,0,0,1,2.89,4.81A6.49,6.49,0,0,1,16.6,15.05ZM12,6a4.5,4.5,0,1,0,4.5,4.5A4.51,4.51,0,0,0,12,6Zm0,7a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,12,13Z">
										</path>
									</svg> {{ single.location }}</a>

								<span class="article__date"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
										<path
											d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20ZM14.09814,9.63379,13,10.26807V7a1,1,0,0,0-2,0v5a1.00025,1.00025,0,0,0,1.5.86621l2.59814-1.5a1.00016,1.00016,0,1,0-1-1.73242Z">
										</path>
									</svg> {{ getMomentDate(single.date) }}</span>
							</div>

							<p v-html="single.content"></p>

						</div>
						<!-- end article content -->

						<!-- share -->
						<div class="share">
							<VueShare />
						</div>
						<!-- end share -->
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import axios from "axios";
import moment from "moment";
import VueShare from '@/components/VueShare.vue'

const url = "https://new.tuneafrique.com/api/events/";

export default {
	name: "EventDetails",
	components: { VueShare },
	data() {
		return {
			single: {},
			id: 0,
			title: "",
			baseUrl: "https://tuneafrique.com/files/",
		};
	},
	created() {
		this.slug = this.$route.params.slug;
		this.id = this.$route.params.id;
	},
	mounted() {
		axios.get(url + this.id).then((response) => {
			this.single = response.data.data;
		});
	},
	methods: {
		getMomentDate: function (date) {
			return moment(date, "YYYYMMDD").format("LL");
		},
	},
};
</script>
