<template>
<Header />
<Side />
<Blogs />
<Footer />
</template>

<script>
// @ is an alias to /src
import Blogs from '@/components/blog/index.vue'
import Header from '@/components/Header.vue'
import Side from '@/components/Side.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Blog',
  components: {
    Header,
    Side,
    Footer,
    Blogs
  }
}
</script>
