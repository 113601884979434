<template>
<Header />
<Side />
<SPage />
<Footer />
</template>

<script>
// @ is an alias to /src
import SPage from '@/components/spage.vue'
import Header from '@/components/Head.vue'
import Side from '@/components/Side.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'ErrorPage',
  components: {
    Header,
    Side,
    Footer,
    SPage
  }
}
</script>
