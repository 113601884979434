<template>
	<main class="main">
		<div class="container-fluid">
			<div class="row row--grid">
				<!-- breadcrumb -->
				<div class="col-12">
					<ul class="breadcrumb breadcrumb--hidden-desk">
						<li class="breadcrumb__item">
							<router-link to="/">Home</router-link>
						</li>
						<li class="breadcrumb__item">
							<router-link to="/blog">Blog</router-link>
						</li>
						<li class="breadcrumb__item breadcrumb__item--active">Article</li>
					</ul>
				</div>
				<!-- end breadcrumb -->

				<div class="col-12 col-xl-10 offset-xl-1">
					<div class="article">
						<!-- article content -->
						<div class="article__content">
							<img :src="baseUrl + single.main" alt="" />

							<div class="article__meta">
								<a href="#" class="article__category"
									>{{ single.views }} Views</a
								>

								<span class="article__date"
									><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
										<path
											d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20ZM14.09814,9.63379,13,10.26807V7a1,1,0,0,0-2,0v5a1.00025,1.00025,0,0,0,1.5.86621l2.59814-1.5a1.00016,1.00016,0,1,0-1-1.73242Z"
										></path>
									</svg>
									{{ getMomentDate(single.created) }}</span
								>
							</div>
							<h1>{{ single.title }}</h1>
							<p v-html="single.content"></p>
						</div>
						<!-- end article content -->

						<!-- share -->
						<div class="share">
							<VueShare />
						</div>
						<!-- end share -->
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
	import axios from "axios";
	import moment from "moment";
	import VueShare from '@/components/VueShare.vue'
	const url = "https://tuneafrique.com/pages/blogs/";

	export default {
		name: "BlogDetails",
		components: {VueShare},
		data() {
			return {
				single: {},
				id: 0,
				title: "",
				baseUrl: "https://tuneafrique.com/files/",
			};
		},
		created() {
			this.title = this.$route.params.title;
			this.id = this.$route.params.id;
		},
		mounted() {
			axios.get(url + this.id).then((response) => {
				this.single = response.data;
			});
		},
		methods: {
			getMomentDate: function (date) {
				return moment(date, "YYYYMMDD").format("LL");
			},
		},
	};
</script>
