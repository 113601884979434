<template>
	<main class="main">
		<div class="container-fluid">
			<div class="row row--grid">
				<!-- breadcrumb -->
				<div class="col-12">
					<ul class="breadcrumb">
						<li class="breadcrumb__item">
							<router-link to="/">Home</router-link>
						</li>
						<li class="breadcrumb__item breadcrumb__item--active">Releases</li>
					</ul>
				</div>
				<!-- end breadcrumb -->
			</div>

			<!-- releases -->
			<div class="row row--grid">
				<div class="col-12">
					<div class="row row--grid">
						<div class="col-6 col-sm-4 col-lg-2" v-for="song in results" :key="song.index">
							<div class="album">
								<div class="album__cover">
									<img :src="'https://tuneafrique.com/files/' +
							song.bar_code +
							'/' +
							song.artwork
							" alt="" />
									<a :href="song.song_link" target="_blank"><svg xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24">
											<path
												d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z">
											</path>
										</svg></a>
									<span class="album__stat">

									</span>
								</div>
								<div class="album__title">
									<h3>
										<router-link :to="{
							name: 'SongDetail',
							params: {
								id: song.id,
								title: song.title.toLowerCase(),
							},
						}">{{ song.title }}</router-link>
									</h3>
									<span><router-link :to="{
							name: 'ArtistDetail',
							params: {
								id: song.artist_id,
								stage_name: song.stage_name
									? song.stage_name.toLowerCase()
									: 'artist-tuneafrique-artist',
							},
						}">{{ song.stage_name }}</router-link></span>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
			<!-- end releases -->
		</div>
	</main>
</template>
<script>
import axios from "axios";
import moment from "moment";
const url = "https://tuneafrique.com/";

export default {
	name: "Index",
	data() {
		return {
			results: [],
		};
	},
	mounted() {
		axios.get("https://new.tuneafrique.com/api/songs").then((response) => {
			this.results = response.data.data;
		});
	},
	methods: {
		getMomentDate: function (date) {
			return moment(date, "YYYYMMDD").fromNow();
		},
	},
};
</script>
