<template>

    <ShareNetwork
        v-for="network in networks"
            :network="network.network"
                :key="network.network"            
                :url="sharing.url"
            :title="sharing.title"                                               
        :quote="sharing.quote"
        :hashtags="sharing.hashtags"
        :twitterUser="
        sharing.twitterUser">
            <a :class="network.class" href="##">
                <img :src="network.img" />
                    {{ network.btnTxt }}
            </a>
        &nbsp;
    </ShareNetwork>
</template>
<script>
export default {
data(){
    return {
            sharing: {
                url: `https://stream.tuneafrique.com${this.$route.path}`,
                twitterUser: "tune_afrique",
                title: "share, explore, discover on stream.tuneafrique.com",
                description: "all about music",
                quote: "monitize your music",
                hashtags: "doityourself,artist,music,digitaldistribution,musicdistribution,distro,diy",
            },
            networks: [
                { network: "facebook", name: "Facebook", class: "share__link share__link--fb", btnTxt: " post", img: "https://img.icons8.com/fluency/22/000000/facebook-new.png", color: "#135fc2" },
                { network: "skype", name: "Skype", class: "share__link share__link--sk", btnTxt: " share", img: "https://img.icons8.com/color/22/000000/skype--v1.png", color: "#008cc0" },
                { network: "twitter", name: "Twitter", class: "share__link share__link--tw", btnTxt: " tweet", img: "https://img.icons8.com/color/22/000000/twitter--v2.png", color: "#1781c2" },
                { network: "whatsapp", name: "Whatsapp", class: "share__link share__link--wt", btnTxt: " share", img: "https://img.icons8.com/color/22/000000/whatsapp--v1.png", color: "#31b060"}
            ]
        }
    },
}
</script>