import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Blog from '../views/blog/Blog.vue'
import BlogDetail from '../views/blog/BlogDetail.vue'
import Artist from '../views/artist/Artist.vue'
import ArtistDetail from '../views/artist/ArtistDetail.vue'
import Song from '../views/song/Song.vue'
import SongDetail from '../views/song/SongDetail.vue'
import Event from '../views/event/Event.vue'
import EventDetail from '../views/event/EventDetail.vue'
import SPage from '../views/SPage.vue'
import ErrorPage from '../views/ErrorPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
    {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
    {
    path: '/blog/:id/:title',
    name: 'BlogDetail',
    component: BlogDetail
    },
    {
    path: '/artist',
    name: 'Artist',
    component: Artist
  },
    {
    path: '/artist/:id/:stage_name',
    name: 'ArtistDetail',
    component: ArtistDetail
  },
  {
    path: '/release',
    name: 'Song',
    component: Song
  },
    {
    path: '/release/:id/:title',
    name: 'SongDetail',
    component: SongDetail
  },
  {
    path: '/event',
    name: 'Event',
    component: Event
  },
    {
    path: '/event/:id/:slug',
    name: 'EventDetail',
    component: EventDetail
  },
    {
    path: '/search',
    name: 'SPage',
    component: SPage
  },
  //   {
  //   path: '/404',
  //   name: 'ErrorPage',
  //   component: ErrorPage
  // },
  {
      path: '/pathMatch(.*)*',
      redirect: '/404',
    component: ErrorPage
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
