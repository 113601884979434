<template>
<Header />
<Side />
<Events />
<Footer />
</template>

<script>
// @ is an alias to /src
import Events from '@/components/event/index.vue'
import Header from '@/components/Header.vue'
import Side from '@/components/Side.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Side,
    Footer,
    Events
  }
}
</script>
