<template>
	<main class="main" v-for="single in singleSong" :key="single.index">
		<div class="container-fluid">
			<div class="row row--grid">
				<!-- breadcrumb -->
				<div class="col-12">
					<ul class="breadcrumb">
						<li class="breadcrumb__item">
							<router-link to="/">Home</router-link>
						</li>
						<li class="breadcrumb__item">
							<router-link to="/release">Releases</router-link>
						</li>
						<li class="breadcrumb__item breadcrumb__item--active">Release</li>
					</ul>
				</div>
				<!-- end breadcrumb -->

				<!-- title -->
				<div class="col-12">
					<div class="main__title main__title--page">
						<h1>
							{{ single.artist_profile["stage_name"] }} –
							{{ single.albums["title"] }}
						</h1>
					</div>
				</div>
				<!-- end title -->

				<div class="col-12">
					<div class="release">
						<div class="release__content">
							<div class="release__cover">
								<img :src="'https://tuneafrique.com/files/'+single.albums['bar_code']+
											'/'+single.albums['artwork']" alt="">
							</div>
							<div class="release__stat">
							</div>
							<a :href="single.songs['song_link']" target="_blank" class="release__buy open-modal">Song Link</a>
						</div>

						<div class="release__list" data-scrollbar="true" tabindex="-1" style="overflow: hidden; outline: none;">
							<iframe :src="single.songs['song_link']" style="display: block;width: 100%;border-radius: 20px;overflow: hidden;height: inherit;"></iframe>							
							<div class="scroll-content"></div>
						<div class="scrollbar-track scrollbar-track-x show" style="display: none;"><div class="scrollbar-thumb scrollbar-thumb-x" style="width: 414px; transform: translate3d(0px, 0px, 0px);"></div></div><div class="scrollbar-track scrollbar-track-y show" style="display: block;"><div class="scrollbar-thumb scrollbar-thumb-y" style="height: 165px; transform: translate3d(0px, 0px, 0px);"></div></div></div>
					</div>
				</div>

				<div class="col-12 col-lg-8">
					<div class="article">
						<!-- article content -->
						<div class="article__content">
							<h4>About release</h4>

							<p v-html="single.albums['album_notes']"></p>
						</div>
						<!-- end article content -->

						<!-- share -->
						<div class="share">
							    <ShareNetwork
        v-for="network in networks"
            :network="network.network"
                :key="network.network"            
                :url="single.songs['song_link']"
            :title="sharing.title"                                               
        :quote="sharing.quote"
        :hashtags="sharing.hashtags"
        :twitterUser="
        sharing.twitterUser">
            <a :class="network.class" href="##">
                <img :src="network.img" />
                    {{ network.btnTxt }}
            </a>
        &nbsp;
    </ShareNetwork>
						</div>
						<!-- end share -->
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
	import axios from "axios";
	import moment from "moment";
	import VueShare from '@/components/VueShare.vue'

	const url = "https://tuneafrique.com/pages/songs/";

	export default {
		name: "SongDetails",
		components: {VueShare},
		data() {
			return {
				singleSong: [],
				id: 0,
				title: "",
				baseUrl: "https://tuneafrique.com/files/",
				// personalized share components 
				            sharing: {
                twitterUser: "tune_afrique",
                title: "share, explore, discover on stream.tuneafrique.com",
                description: "all about music",
                quote: "monitize your music",
                hashtags: "doityourself,artist,music,digitaldistribution,musicdistribution,distro,diy",
            },
            networks: [
                { network: "facebook", name: "Facebook", class: "share__link share__link--fb", btnTxt: " post", img: "https://img.icons8.com/fluency/22/000000/facebook-new.png", color: "#135fc2" },
                { network: "skype", name: "Skype", class: "share__link share__link--sk", btnTxt: " share", img: "https://img.icons8.com/color/22/000000/skype--v1.png", color: "#008cc0" },
                { network: "twitter", name: "Twitter", class: "share__link share__link--tw", btnTxt: " tweet", img: "https://img.icons8.com/color/22/000000/twitter--v2.png", color: "#1781c2" },
                { network: "whatsapp", name: "Whatsapp", class: "share__link share__link--wt", btnTxt: " share", img: "https://img.icons8.com/color/22/000000/whatsapp--v1.png", color: "#31b060"}
            ]
			};
		},
		created() {
			this.title = this.$route.params.title;
			this.id = this.$route.params.id;
		},
		mounted() {
			axios.get(url + this.id).then((response) => {
				this.singleSong = response.data;
			});
		},
		methods: {
			getMomentDate: function (date) {
				return moment(date, "YYYYMMDD").format("LL");
			},
		},
	};
</script>
