<template>
	<main class="main">
		<div class="container-fluid">
			<!-- artists -->
			<div class="row row--grid">
				<!-- breadcrumb -->
				<div class="col-12">
					<ul class="breadcrumb">
						<li class="breadcrumb__item"><router-link to="/">Home</router-link></li>
						<li class="breadcrumb__item breadcrumb__item--active">Artists</li>
					</ul>
				</div>
				<!-- end breadcrumb -->

				<!-- title -->
				<div class="col-12">
					<div class="main__title main__title--page">
						<h1>Artists</h1>
					</div>
				</div>
				<!-- end title -->
			</div>

			<div class="row row--grid">
				<div class="col-12">
					<div class="row row--grid">
						<div
							class="col-6 col-sm-4 col-md-3 col-xl-2"
							v-for="artist_profile in results"
							:key="artist_profile.index"
						>						
								<router-link
								
									:to="{
										name: 'ArtistDetail',
										params: {
											id: artist_profile.artist_profile['id'],
											stage_name: artist_profile.artist_profile['stage_name'].toLowerCase(),
										},
									}"
									class="artist"
								>
										<div class="artist__cover">
										<img
											:src="'https://tuneafrique.com/files/' + artist_profile.artist_profile['avatar']"
											alt=""
											style="height: 175px;"/>
									</div>
									<h3 class="artist__title">{{ artist_profile.artist_profile['stage_name'] }}</h3>
								</router-link>
						</div>
					</div>

					<!-- <button class="main__load" type="button">Load more</button> -->
				</div>
			</div>
			<!-- end artists -->
		</div>
	</main>
</template>
<script>
	import axios from "axios";
	const url = "https://tuneafrique.com/";

	export default {
		name: "Index",
		data() {
			return {
				results: [],
				res: [],
			};
		},
		mounted() {
			axios.get(url+"pages/artists").then((response) => {
				this.res = response.data;
				this.results = this.res.slice(0, 60);
			});
		},
	};
</script>
