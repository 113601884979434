<template>
	<main class="main">
		<div class="container-fluid">
			<div class="row row--grid">
				<!-- breadcrumb -->
				<div class="col-12">
					<ul class="breadcrumb">
						<li class="breadcrumb__item">
							<router-link to="/">Home</router-link>
						</li>
						<li class="breadcrumb__item breadcrumb__item--active">Blogs</li>
					</ul>
				</div>
				<!-- end breadcrumb -->
			</div>

			<div class="tab-content">
				<div class="tab-pane fade show active" id="tab-1" role="tabpanel">
					<div class="row row--grid">
						<!-- post -->
						<div
							class="col-12 col-sm-6 col-lg-3"
							v-for="result in results"
							:key="result"
						>
							<div v-for="blog in result" :key="blog.id">
								<div class="post">
									<router-link
										:to="{
											name: 'BlogDetail',
											params: { id: blog.id, title: blog.title.toLowerCase() },
										}"
										class="post__img"
									>
										<img
											:src="'https://tuneafrique.com/files/' + blog.thumb"
											alt=""
											style="height: 186px"
										/>
									</router-link>

									<div class="post__content">
										<a href="#" class="post__category">{{ blog.views }} views</a>
										<h3 class="post__title">
											<router-link
												:to="{
													name: 'BlogDetail',
													params: {
														id: blog.id,
														title: blog.title.toLowerCase(),
													},
												}"
												>{{ blog.title.substring(0, 29) + ".." }}</router-link
											>
										</h3>
										<div class="post__meta">
											<span class="post__date"
												><svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
												>
													<path
														d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20ZM14.09814,9.63379,13,10.26807V7a1,1,0,0,0-2,0v5a1.00025,1.00025,0,0,0,1.5.86621l2.59814-1.5a1.00016,1.00016,0,1,0-1-1.73242Z"
													></path>
												</svg>
												{{ getMomentDate(blog.created) }}</span
											>
											<!-- <span class="post__comments"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,9H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2Zm-4,4H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2ZM12,2A10,10,0,0,0,2,12a9.89,9.89,0,0,0,2.26,6.33l-2,2a1,1,0,0,0-.21,1.09A1,1,0,0,0,3,22h9A10,10,0,0,0,12,2Zm0,18H5.41l.93-.93a1,1,0,0,0,0-1.41A8,8,0,1,1,12,20Z"></path></svg> 18</span> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- end post -->
					</div>

				
				</div>
			</div>
		</div>
	</main>
</template>
<script>
	import axios from "axios";
	import moment from "moment";
	const base_url = "https://tuneafrique.com/";

	export default {
		name: "Index",
		data() {
			return {
				results: [],
				res: [],
			};
		},
		mounted() {
			axios.get(base_url+"pages/blogs").then((response) => {
				this.res = response.data;
				this.results = this.res.slice(0, 60);
			});
		},
		methods: {
			getMomentDate: function (date) {
				return moment(date, "YYYYMMDD").fromNow();
			},
		},
	};
</script>
