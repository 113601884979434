<template>
<Header />
<Side />
<Index />
<Footer />
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Index from '@/components/home/Index.vue'
import Header from '@/components/Header.vue'
import Side from '@/components/Side.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld,
    Header,
    Side,
    Footer,
    Index
  }
}
</script>
