<template>
    <main class="main">
		<div class="container-fluid">
			<div class="row row--grid">

				<!-- breadcrumb -->
				<div class="col-12">
					<ul class="breadcrumb">
						<li class="breadcrumb__item"><router-link to="/">Home</router-link></li>
						<li class="breadcrumb__item breadcrumb__item--active">Search</li>
					</ul>
				</div>
				<br>
				<br>
				<br>
				<!-- end breadcrumb -->
				<div class="col-12" id="searchFilter">
					<input v-model="search" @keyup="getSearchResults" type="text" placeholder="Search Artist, Album, Event or Blog" style="    width: calc(100% - 30px);
    height: 40px;
    background-color: #e6eaf2;
    color: #0e2d49;
    font-size: 16px;
    border-radius: 20px;
    border: none;
    padding: 0 20px;
    ">
				<button type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg></button>
				<button type="button" class="close"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg></button>

				</div>

			<section v-if="results != ''">		

				<section class="row row--grid" v-if="songs != ''">
					<div class="col-12">
						<div class="main__title">
							<h2>Releases</h2>
						</div>
					</div>
					<!-- release -->
					<template  v-for="songResult in songs" :key="songResult">
						<div
							class="col-6 col-sm-4 col-lg-2"
							v-for="ssong in songResult" :key="ssong.index"
						>
							<div class="album">
								<div class="album__cover">								
									<img src="../../public/img/song.png"/>
									<!-- <img
										:src="
											'https://tuneafrique.com/files/' +
											song.albums['bar_code'] +
											'/' +
											song.albums['artwork']
										"
										alt=""
									/> -->
								</div>
								<div class="album__title">
									<h3>
										<router-link
											:to="{
												name: 'SongDetail',
												params: {
													id: ssong.id,
													title: ssong.stage_name.toLowerCase(),
												},
											}"
										>
											{{ ssong.stage_name }}
										</router-link>
									</h3>						
								</div>
							</div>
						</div>
					</template>
				</section>
					
				<section class="row row--grid" v-if="artists != ''">
				<!-- title -->
				<div class="col-12">
					<div class="main__title">
						<h2>Artists</h2>
					</div>
				</div>
				<!-- end release -->
				<!-- artist -->
				<div class="col-12">
					<div class="main__carousel-wrap">
						<div class="row row--grid">
							<template v-for="artistResult in artists" :key="artistResult">
							<div
								class="col-6 col-sm-4 col-lg-2"
								v-for="artist in artistResult"
								:key="artist.index"
							>
								<router-link
									:to="{
										name: 'ArtistDetail',
										params: {
											id: artist.id,
											stage_name:
												artist.stage_name.toLowerCase(),
										},
									}"
									class="artist"
								>
									<div class="">
									<!-- <div class="artist__cover"> -->
										<!-- <img
											:src="'https://tuneafrique.com/files/' + artist.artist_profile['avatar']"
											alt=""
											style="width: 175px; height: 155px"
										/> -->
										<img src="../../public/img/artist.png" style="width: 175px; height: 155px"/>
									</div>
									<h3 class="artist__title">
										{{ artist.stage_name }}
									</h3>
								</router-link>
							</div>
							</template>
						</div>
					</div>
				</div>
				<!-- end artist  -->
			</section>

			<section class="row row--grid" v-if="blogs != ''">
				<!-- title -->
				<div class="col-12">
					<div class="main__title">
						<h2>Blogs</h2>
					</div>
				</div>
				<!-- end title -->
				<template v-for="blogResult in blogs" :key="blogResult">
				<div
					class="col-12 col-sm-6 col-lg-3"
					v-for="blog in blogResult"
					:key="blog.index"
				>
					<!-- post -->

					<div class="post">
						<router-link
							:to="{
								name: 'BlogDetail',
								params: {
									id: blog.id,
									title: blog.stage_name.toLowerCase(),
								},
							}"
							class="post__img"
						>
					
						<img src="https://img.icons8.com/external-wanicon-lineal-wanicon/64/000000/external-blog-digital-content-wanicon-lineal-wanicon.png"/>
							<!-- <img
								:src="'https://tuneafrique.com/files/' + blog.Blog['thumb']"
								alt=""
								style="height: 186px"
							/> -->
						</router-link>

						<div class="post__content">
							
							<h3 class="post__title">
								<router-link
									:to="{
										name: 'BlogDetail',
										params: {
											id: blog.id,
											title: blog.location.toLowerCase(),
										},
									}"
								>
									{{ blog.stage_name.substring(0, 29) + ".." }}
								</router-link>
							</h3>
						</div>
					</div>
					<!-- </div> -->
					<!-- end post -->
				</div>
				</template>
			</section>

						<!-- events -->
			<section class="row row--grid">
				<!-- title -->
				<div class="col-12">
					<div class="main__title">
						<h2>Events</h2>					
					</div>
				</div>
				<!-- end title -->
				<div class="row row--grid" v-if="events != ''">
					<div class="col-12 col-md-6 col-xl-3">
						<div class="row row--grid">
							<template v-for="eventResult in events" :key="eventResult">
							<div class="col-xl-12" v-for="event in eventResult"
							:key="event.index">
						 <router-link
									:to="{
										name: 'EventDetail',
										params: {
											id: event.id,
											slug: event.stage_name.toLowerCase(),
										},
									}"																	
								> 
							<div class="event" data-bg="https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-event-camping-kiranshastry-lineal-kiranshastry-1.png" 
									style="'background: url('https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-event-camping-kiranshastry-lineal-kiranshastry-1.png') center center / cover no-repeat;">
								<h3 class="event__title">
									 <router-link
									:to="{
										name: 'EventDetail',
										params: {
											id: event.id,
											slug: event.stage_name.toLowerCase(),
										},
									}"																	
								    >
                                    {{event.stage_name}}
                                    </router-link>
								</h3>
								<p class="event__address"> 
									{{event.location}}
								 </p>
							</div>	
							</router-link> 											
						</div>
						</template>
					</div>
				</div>
			</div>
			</section>
			<!-- end events -->

	
			</section>

			<section  v-else>
				<br>
				<br>
				<br>
				<div class="col-12">
					<div class="row row--grid">
					<h1> No Result To Display :(</h1>
					</div>
				</div>
			</section>
				<!-- end error -->
			</div>
		</div>
	</main>
</template>

<script>
import axios from "axios";

export default {
  name: "Search",

  data() {
    return {
      people: [],
      results: [],
      search: "",
      artist: "artist_profile",
      song: "albums",
      blog: "blogs",
      event: "events",
    };
  },

//   mounted() {
//     alert(this.search);
//   },

  methods: {
	getSearchResults(){
			axios.get(`https://tuneafrique.com/pages/general_search/`+this.search).then((response) => {
				this.results = response.data;
			});
	}
  },
  computed: {
		songs: function () {
				return this.results.filter(i => i[0].type === 'albums')
			},
		artists: function () {
				return this.results.filter(i => i[0].type === 'artist_profile')
		},
		blogs: function () {
				return this.results.filter(i => i[0].type === 'blogs')
		},
		events: function () {
				return this.results.filter(i => i[0].type === 'events')
		}
	},
  created() {
    this.getSearchResults();
  }
};
</script>