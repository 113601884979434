<template>
	<main class="main">
		<div class="container-fluid">
			<div class="row row--grid">
				<!-- breadcrumb -->
				<div class="col-12">
					<ul class="breadcrumb">
						<li class="breadcrumb__item"><router-link to="/">Home</router-link></li>
						<li class="breadcrumb__item breadcrumb__item--active">Events</li>
					</ul>
				</div>
				<!-- end breadcrumb -->

				<!-- title -->
				<div class="col-12">
					<div class="main__title main__title--page">
						<h1>Events</h1>
					</div>
				</div>
				<!-- end title -->
			</div>

			<div class="row row--grid">
				<div class="col-12">


					<div class="row row--grid">

						<div class="col-12 col-md-6 col-xl-4" v-for="event in results" :key="event.index">
							<a v-if="event.date < currDate()" href="#">

								<div class="event" :data-bg="'https://tuneafrique.com/files/' + event.main"
									:style="'background: url(https://tuneafrique.com/files/' + event.main + ') center center / cover no-repeat;'">
									<span v-if="event.date < currDate()" class="event__out">Ended</span>
									<span v-else>
										<a v-if="event.link" :href="event.link" target="_blank"
											class="event__ticket open-modal"><svg xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24">
												<path
													d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z">
												</path>
											</svg> Buy ticket</a>
										<a v-if="event.link == '' || event.link == null" href="#"
											class="event__ticket open-modal"><svg xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24">
												<path
													d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z">
												</path>
											</svg> Free</a>
									</span>

									<span class="event__time">{{ getMomentTime(event.date) ?
							getMomentTime(event.date) : '' }}</span>
									<h3 class="event__title">
										<router-link to="#">
											{{ event.title }}
										</router-link>
									</h3>
									<p class="event__address">{{ event.location }}</p>
								</div>
							</a>
							<router-link v-else :to="{
							name: 'EventDetail',
							params: {
								id: event.id,
								slug: event.slug.toLowerCase(),
							},
						}">
								<div class="event" :data-bg="'https://tuneafrique.com/files/' + event.main"
									:style="'background: url(https://tuneafrique.com/files/' + event.main + ') center center / cover no-repeat;'">
									<span v-if="event.date < currDate()" class="event__out">Ended</span>
									<span v-else>
										<a v-if="event.link" :href="event.link" target="_blank"
											class="event__ticket open-modal"><svg xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24">
												<path
													d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z">
												</path>
											</svg> Buy ticket</a>
										<a v-if="event.link == '' || event.link == null" href="#"
											class="event__ticket open-modal"><svg xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24">
												<path
													d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z">
												</path>
											</svg> Free</a>
									</span>
									<span class="event__time">{{ getMomentTime(event.date) ?
							getMomentTime(event.date) : '' }}</span>
									<h3 class="event__title">
										<router-link :to="{
							name: 'EventDetail',
							params: {
								id: event.id,
								slug: event.slug.toLowerCase(),
							},
						}">
											{{ event.title }}
										</router-link>
									</h3>
									<p class="event__address">
										{{ event.location }}
									</p>
								</div>
							</router-link>
						</div>

					</div>


				</div>
			</div>

		</div>
	</main>
</template>
<script>
import axios from "axios";
import moment from "moment";
const base_url = "https://tuneafrique.com/";

export default {
	name: "Index",
	data() {
		return {
			results: [],
			res: [],
		};
	},
	mounted() {
		axios.get("https://new.tuneafrique.com/api/events").then((response) => {
			this.res = response.data.data;
			this.results = this.res.slice(0, 60);
		});
	},
	methods: {
		getMomentTime: function (date) {
			return moment(date).format('LL');
		},
		currDate: function () {
			return moment(new Date()).format('YYYY-MM-DD');
		},

	},
};
</script>
