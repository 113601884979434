<template>
<!-- {{singleArtist[0].artist_profile}} -->
	<main class="main" >
		<div class="container-fluid">
			<div class="row row--grid">
				<div class="col-12">
					<div class="article article--page" v-for="single in singleArtist" :key="single.index">
						<!-- article content -->
						<div class="article__content">
							<div class="article__artist">
								<img
									:src="baseUrl + single.artist_profile['avatar']"
									alt=""
									style="height: 200px"
								/>
								<div>
									<h1>{{ single.artist_profile["stage_name"] }}</h1>
									<p v-html="single.artist_profile['bio']"></p>
								</div>
							</div>
						</div>
						<!-- end article content -->

						<!-- share -->
						<div class="share">
							<VueShare />
						</div>
						<!-- end share -->
					</div>
				</div>
			</div>

			<!-- releases -->
			<section class="row row--grid" v-if="artistReleases != ''">
				<!-- title -->
				<div class="col-12">
					<div class="main__title">
						<h2>Releases</h2>
					</div>
				</div>
			<!-- end title -->
				 <div class="col-6 col-sm-4 col-lg-2" v-for="song in artistReleases" :key="song.index">
					<div class="album">
						<div class="album__cover">
							<img :src="baseUrl+song.albums['bar_code']+'/'+song.albums['artwork']" alt="" />
							<a :href="song.songs['song_link']" target="_blank"
								><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
									<path
										d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z"
									></path></svg
							></a>
							<span class="album__stat">															
								
							</span>
						</div>
						<div class="album__title">
							<h3>
								<router-link
											:to="{
												name: 'SongDetail',
												params: {
													id: song.albums['id'],
													title: song.albums['title'].toLowerCase(),
												},
											}"
											>
								{{song.albums['title']}}</router-link
										></h3>
							<span
								><router-link
											:to="{
												name: 'ArtistDetail',
												params: {
													id: song.albums['artist_id'],
													stage_name: song.albums['artist_name']
														? song.albums['artist_name'].toLowerCase()
														: 'artist-tuneafrique-artist',
												},
											}"
											>{{song.albums['artist_name']}}</router-link
										>
							</span>
						</div>
					</div>
				</div>
			</section>
			<!-- end releases -->

			<!-- events -->
			<section class="row row--grid" v-if="artistEvents != ''">
				<!-- title -->
				<div class="col-12">
					<div class="main__title">
						<h2>Events</h2>
					</div>
				</div>
				<!-- end title -->
					<div class="col-12">
						<div class="row row--grid">
							<div class="col-12 col-md-6 col-xl-3" v-for="event in artistEvents"
							:key="event.index">
						 <router-link
									:to="{
										name: 'EventDetail',
										params: {
											id: event.Event['id'],
											slug: event.Event['slug'].toLowerCase(),
										},
									}"																	
								> 
							<div class="event" :data-bg="'https://tuneafrique.com/files/'+event.Event['main']" :style="'background: url(https://tuneafrique.com/files/'+event.Event['main']+') center center / cover no-repeat;'">
								<span class="event__out" v-if="event.Event['date'] < new Date()" >Ended</span>
                                <span v-else>
                                <a v-if="event.Event['link']" :href="event.Event['link']" target="_blank" class="event__ticket open-modal"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z"></path></svg> Buy ticket</a>
                                </span>
								
								<h3 class="event__title">
									 <router-link
									:to="{
										name: 'EventDetail',
										params: {
											id: event.Event['id'],
											slug: event.Event['slug'].toLowerCase(),
										},
									}"																	
								    >
                                    {{event.Event['title']}}
                                    </router-link>
								</h3>
								<p class="event__address"> 
									{{event.Event['location']}}
								 </p>
							</div>	
							</router-link> 											
						</div>
					</div>
				</div>
			</section>
			<!-- end events -->
		</div>
	</main>
</template>
<script>
	import axios from "axios";
	import moment from "moment";
	import VueShare from '@/components/VueShare.vue'

	const url = "https://tuneafrique.com/pages/";

	export default {
		name: "ArtistDetails",
		components: {VueShare},
		data() {
			return {
				singleArtist: {},
				artistReleases: {},
				artistEvents: {},
				baseUrl: "https://tuneafrique.com/files/",
			};
		},
		created() {
			this.stage_name = this.$route.params.stage_name;
			this.id = this.$route.params.id;
		},
		mounted() {
			axios.get(url + 'artists/' + this.id).then((response) => {
				this.singleArtist = response.data;
			});
			axios.get(url +'artistReleases/'+ this.id).then((response) => {
				this.artistReleases = response.data;
			});
			axios.get(url +'artistEvents/'+ this.id).then((response) => {
				this.artistEvents = response.data;
			});
		},
		methods: {
			getMomentDate: function (date) {
				return moment(date, "YYYYMMDD").format("LL");
			},
		},
	};
</script>
