<template>
	<!-- main content -->
	<main class="main">
		<div class="container-fluid">
			<!-- slider -->
			<section class="row">
				<div class="col-12">
					<div id="demo" class="carousel slide" data-ride="carousel">
						<!-- Indicators -->
						<ul class="carousel-indicators">
							<li data-target="#demo" data-slide-to="0" class="active"></li>
							<li data-target="#demo" data-slide-to="1"></li>
							<li data-target="#demo" data-slide-to="2"></li>
						</ul>

						<!-- The slideshow -->
						<div class="carousel-inner">
							<div class="carousel-item active">
								<img class="d-block w-100" :src="'https://tuneafrique.com/files/' + TaSlider.image"
									alt="TuneAfrique Default Banner">
								<div class="carousel-caption">
									<h3>{{ TaSlider.title }}</h3>
									<a :href="TaSlider.url1" target="_blank" class="btn btn-dark">{{ TaSlider.link1
										}}</a> -
									<a :href="TaSlider.url2" target="_blank" class="btn btn-danger">{{ TaSlider.link2
										}}</a>
								</div>
							</div>
							<div class="carousel-item" v-for="slide in sliders" :key="slide.index">
								<img class="d-block w-100" :src="'https://tuneafrique.com/files/' + slide.image"
									alt="TuneAfrique Other Banners">
								<div class="carousel-caption">
									<h3>{{ slide.title }}</h3>
									<a :href="slide.url1" target="_blank" class="btn btn-dark">{{
									slide.link1 }}</a> -
									<a :href="slide.url2" target="_blank" class="btn btn-danger">{{
									slide.link2 }}</a>
								</div>

							</div>
						</div>

						<!-- Left and right controls -->
						<a class="carousel-control-prev" href="#demo" data-slide="prev">
							<span class="carousel-control-prev-icon"></span>
						</a>
						<a class="carousel-control-next" href="#demo" data-slide="next">
							<span class="carousel-control-next-icon"></span>
						</a>
					</div>

				</div>

			</section>

			<!-- end slider -->

			<!-- releases -->
			<section class="row row--grid">
				<!-- title -->
				<div class="col-12">
					<div class="main__title">
						<h2>New Releases</h2>

						<router-link to="/release" class="main__link">See all
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
								<path
									d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
							</svg></router-link>
					</div>
				</div>
				<!-- end title -->
				<div class="col-6 col-sm-4 col-lg-2" v-for="song in songs" :key="song.index">
					<div class="album">
						<div class="album__cover">
							<img :src="'https://tuneafrique.com/files/' +
									song.bar_code +
									'/' +
									song.artwork
									" alt="" />
							<a :href="song.song_link" target="_blank"><svg xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24">
									<path
										d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" />
								</svg></a>
							<span class="album__stat">

							</span>
						</div>
						<div class="album__title">
							<h3>
								<router-link :to="{
									name: 'SongDetail',
									params: {
										id: song.id,
										title: song.title.toLowerCase(),
									},
								}">
									{{ song.title }}
								</router-link>
							</h3>
							<span>
								<router-link :to="{
									name: 'ArtistDetail',
									params: {
										id: song.artist_id,
										stage_name: song.stage_name
											? song.stage_name.toLowerCase()
											: 'artist-tuneafrique-artist',
									},
								}">
									{{ song.stage_name }}
								</router-link>
							</span>
						</div>
					</div>
				</div>
			</section>
			<!-- end releases -->

			<!-- widget -->
			<section class="row row--grid">
				<div class="col-12">
					<div class="main__title">
						<h2>SoundCloud Snippets</h2>

					</div>
				</div>
				<br>
				<div class="col-12">
					<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay"
						src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/564811635&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
					<div
						style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
						<a href="https://soundcloud.com/tune-afrique" title="TuneAfrique" target="_blank"
							style="color: #cccccc; text-decoration: none;">TuneAfrique</a> · <a
							href="https://soundcloud.com/tune-afrique/sets/tuneafrique" title="Afro Discovery"
							target="_blank" style="color: #cccccc; text-decoration: none;">Afro Discovery</a>
					</div>
				</div>
			</section>
			<!-- end widget  -->

			<!-- events -->
			<section class="row row--grid">
				<!-- title -->
				<div class="col-12">
					<div class="main__title">
						<h2>Upcoming Events</h2>
						<router-link to="/event" class="main__link">See all
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
								<path
									d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
							</svg></router-link>
					</div>
				</div>
				<!-- end title -->
				<div class="col-12">
					<div class="row row--grid">
						<div class="col-12 col-md-6 col-xl-3" v-for="event in events" :key="event.index">
							<a v-if="event.date < currDate()" href="#">

								<div class="event" :data-bg="'https://tuneafrique.com/files/' + event.main"
									:style="'background: url(https://tuneafrique.com/files/' + event.main + ') center center / cover no-repeat;'">
									<span v-if="event.date < currDate()" class="event__out">Ended</span>
									<span v-else>
										<a v-if="event.link" :href="event.link" target="_blank"
											class="event__ticket open-modal"><svg xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24">
												<path
													d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z">
												</path>
											</svg> Buy ticket</a>
										<a v-if="event.link == '' || event.link == null" href="#"
											class="event__ticket open-modal"><svg xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24">
												<path
													d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z">
												</path>
											</svg> Free</a>
									</span>

									<span class="event__time">{{ getMomentTime(event.date) ?
									getMomentTime(event.date) : '' }}</span>
									<h3 class="event__title">
										<router-link to="#">
											{{ event.title }}
										</router-link>
									</h3>
									<p class="event__address">{{ event.location }}</p>
								</div>
							</a>
							<router-link v-else :to="{
									name: 'EventDetail',
									params: {
										id: event.id,
										slug: event.slug.toLowerCase(),
									},
								}">
								<div class="event" :data-bg="'https://tuneafrique.com/files/' + event.main"
									:style="'background: url(https://tuneafrique.com/files/' + event.main + ') center center / cover no-repeat;'">
									<span v-if="event.date < currDate()" class="event__out">Ended</span>
									<span v-else>
										<a v-if="event.link" :href="event.link" target="_blank"
											class="event__ticket open-modal"><svg xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24">
												<path
													d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z">
												</path>
											</svg> Buy ticket</a>
										<a v-if="event.link == '' || event.link == null" href="#"
											class="event__ticket open-modal"><svg xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24">
												<path
													d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z">
												</path>
											</svg> Free</a>
									</span>
									<span class="event__time">{{ getMomentTime(event.date) ?
									getMomentTime(event.date) : '' }}</span>
									<h3 class="event__title">
										<router-link :to="{
									name: 'EventDetail',
									params: {
										id: event.id,
										slug: event.slug.toLowerCase(),
									},
								}">
											{{ event.title }}
										</router-link>
									</h3>
									<p class="event__address">
										{{ event.location }}
									</p>
								</div>
							</router-link>
						</div>
					</div>
				</div>
			</section>
			<!-- end events -->

			<!-- articts -->
			<section class="row row--grid">
				<!-- title -->
				<div class="col-12">
					<div class="main__title">
						<h2>Artists</h2>

						<router-link to="/artist" class="main__link">See all
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
								<path
									d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
							</svg></router-link>
					</div>
				</div>
				<!-- end title -->
				<!-- artist -->
				<div class="col-12">
					<div class="main__carousel-wrap">
						<div class="row row--grid">
							<div class="col-6 col-sm-4 col-lg-2" v-for="artist in artists" :key="artist.index">
								<router-link :to="{
									name: 'ArtistDetail',
									params: {
										id: artist.artist_profile['id'],
										stage_name:
											artist.artist_profile['stage_name'].toLowerCase(),
									},
								}" class="artist">
									<div class="artist__cover">
										<img :src="'https://tuneafrique.com/files/' + artist.artist_profile['avatar']"
											alt="" style="height: 175px" />
									</div>
									<h3 class="artist__title">
										{{ artist.artist_profile["stage_name"] }}
									</h3>
								</router-link>
							</div>
						</div>
					</div>
				</div>
				<!-- end artist  -->
			</section>
			<!-- end articts -->

			<!-- news -->
			<section class="row row--grid">
				<!-- title -->
				<div class="col-12">
					<div class="main__title">
						<h2>Blog</h2>

						<router-link to="/blog" class="main__link">See all
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
								<path
									d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
							</svg></router-link>
					</div>
				</div>
				<!-- end title -->
				<div class="col-12 col-sm-6 col-lg-3" v-for="blog in blogs" :key="blog.index">
					<!-- post -->

					<div class="post">
						<router-link :to="{
									name: 'BlogDetail',
									params: {
										id: blog.Blog['id'],
										title: blog.Blog['title'].toLowerCase(),
									},
								}" class="post__img">
							<img :src="'https://tuneafrique.com/files/' + blog.Blog['thumb']" alt=""
								style="height: 186px" />
						</router-link>

						<div class="post__content">
							<a href="#" class="post__category">{{ blog.Blog["views"] }} views</a>
							<h3 class="post__title">
								<router-link :to="{
									name: 'BlogDetail',
									params: {
										id: blog.Blog['id'],
										title: blog.Blog['title'].toLowerCase(),
									},
								}">
									{{ blog.Blog["title"].substring(0, 29) + ".." }}
								</router-link>
							</h3>
							<div class="post__meta">
								<span class="post__date"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
										<path
											d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20ZM14.09814,9.63379,13,10.26807V7a1,1,0,0,0-2,0v5a1.00025,1.00025,0,0,0,1.5.86621l2.59814-1.5a1.00016,1.00016,0,1,0-1-1.73242Z" />
									</svg>
									{{ getMomentDate(blog.Blog["created"]) }}
								</span>
							</div>
						</div>
					</div>
					<!-- </div> -->
					<!-- end post -->
				</div>
			</section>
			<!-- end news -->
		</div>
	</main>
	<!-- end main content -->
</template>
<script>
import axios from "axios";
import moment from "moment";
const url = "https://tuneafrique.com/";

export default {
	name: "Index",
	data() {
		return {
			TaSlider: [],
			sliders: [],
			blogs: [],
			artists: [],
			songs: [],
			events: [],
		};
	},

	mounted() {
		axios.get("https://new.tuneafrique.com/api/sliders/1").then((response) => {
			this.TaSlider = response.data.data;
		});
		axios.get("https://new.tuneafrique.com/api/sliders").then((response) => {
			this.sliders = response.data.data;
		});
		axios.get(url + "pages/blogs/").then((response) => {
			this.blogs = response.data.slice(0, 4);
		});
		axios.get(url + "pages/artists/").then((response) => {
			this.artists = response.data.slice(0, 6);
		});
		axios.get("https://new.tuneafrique.com/api/songs").then((response) => {
			this.songs = response.data.data.slice(0, 6);
		});
		axios.get("https://new.tuneafrique.com/api/events").then((response) => {
			this.events = response.data.data.slice(0, 4);
		});
	},
	methods: {
		getMomentDate: function (date) {
			return moment(date, "YYYYMMDD").fromNow();
		},
		getMomentTime: function (date) {
			return moment(date).format('LL');
		},
		currDate: function () {
			return moment(new Date()).format('YYYY-MM-DD');
		},
	},
};
</script>
<style scoped>
@import url('../../../public/css/bootstrap.min.css');

@media only screen and (min-width: 768px) {
	.w-100 {
		width: 100% !important;
		height: 470px !important;
	}
}
</style>